<template>

<div>

	<com-title text="Library" />

	<com-item route="Library.Games" text="Games" />
	<com-item route="Library.Collections" text="Collections" />
	<com-item route="Library.Settings" text="Settings" />

</div>

</template>

<script>

export default {

	components: {
		'com-title': () => import('./../../panel/common/Title'),
		'com-item': () => import('./../../panel/common/Item')
	}

}

</script>

<style scoped>

</style>
